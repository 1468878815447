import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../../components/Hero'
import Image from '../../components/Image'
import Article, { Text, Title } from '../../components/Article'

import Button from '../../components/Button'
import Section from '../../components/Section'
import { EmailSignature } from '../../components/Brandbook'

import ContactCard from '../../components/ContactCard'

import imagePPT from '../../assets/brandbook/images/for-the-office-PPT.jpg'

export default function ForTheOffice ({ data: { hero }, ...props }) {
  return (
    <>
      <Hero {...hero} light title="For the office" />

      <Article>
        <Text>
          The tools you need to get your job done in the most effecient way
          possible.
        </Text>

        <Title level="2">PowerPoint</Title>
        <Text>Make sure you build your NCL presentations on brand.</Text>
        <Image src={imagePPT} />

        <Title level="3">NCL template</Title>
        <Text>Start from scratch with NCL's official PPT template.</Text>

        <Button
          secondary
          to="https://drive.google.com/file/d/1VLhtLwczXIEaNbPRYA0ty8t6D8DNkAbI/view?usp=sharing"
        >
          Download
        </Button>

        <Title level="3">
          20180418_NCL_FishExporters&shy;_AES_April2018.pptx
        </Title>
        <Text>
          Re-use redesigned slides from Eivind's exisiting presentation.
        </Text>
        <Button
          secondary
          to="https://drive.google.com/file/d/1XxIkddSEQUiE0FFqiSf7eQcsaHDg5gMz/view?usp=sharing"
        >
          Download
        </Button>

        <Title level="2">E-mail signature</Title>

        <Text>
          Copy the signature below, paste it into your mail client and replace
          the data (first name, last name, direct and mobile) with your own
          contact information.{' '}
        </Text>

        <EmailSignature />
      </Article>
      <Section>
        <Title>Any questions or in need of assistance?</Title>
        <ContactCard who="kenneth-johanson" />
        <ContactCard who="ada-jakobsen" />
      </Section>
    </>
  )
}

ForTheOffice.propTypes = {
  data: PropTypes.object.isRequired,
}

ForTheOffice.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/fog.jpg/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
